<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div
            v-for="mailTemplate in mailTemplates"
            :key="mailTemplate.module"
            class="row mb-2"
          >
            <div class="col-md-3">
              <MultiSelectInput
                v-model="mailTemplate.mailTemplateId"
                :options="templates"
                :key="mailTemplate.mailTemplateId"
                :multiple="false"
                :textLabel="$t(mailTemplate.label)"
                label="subject"
                trackBy="id"
                class="pb-8 pr-6"
                moduleName="mailTemplates"
                :search-param-name="'search_string'"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('CC')"
                v-model="mailTemplate.cc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('BCC')"
                v-model="mailTemplate.bcc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('Sender Mail')"
                v-model="mailTemplate.senderMail"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="createTemplate"
          class="d-flex align-items-center gap-1"
          v-if="$can(`${$route.meta.permission}.save`)"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Save Template") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import MultiSelectInput from "@/components/MultiSelectInput.vue";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("mailTemplates", ["templates"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Mail Template Assignment"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      mailTemplates: [
        {
          module: "invoiceTemplate",
          label: "Invoice Template",
          mailTemplateId: "",
          cc: "",
          bcc: "",
          senderMail: "",
        },
        {
          module: "invoiceCorrectionTemplate",
          label: "Invoice Correction Template",
          mailTemplateId: "",
          cc: "",
          bcc: "",
          senderMail: "",
        },
        {
          module: "invoiceStornoTemplate",
          label: "Invoice Storno Template",
          mailTemplateId: "",
          cc: "",
          bcc: "",
          senderMail: "",
        },
        {
          module: "userActivateTemplate",
          label: "User Activate Template",
          mailTemplateId: "",
          cc: "",
          bcc: "",
          senderMail: "",
        },
        {
          module: "userWelcomeTemplate",
          label: "User Welcome Template",
          mailTemplateId: "",
          cc: "",
          bcc: "",
          senderMail: "",
        },
        {
          module: "ticketSystemTemplate",
          label: "Ticket System Template",
          mailTemplateId: "",
          cc: "",
          bcc: "",
          senderMail: "",
        },
        {
          module: "lowCreditsTemplate",
          label: "Low Credits Template",
          mailTemplateId: "",
          cc: "",
          bcc: "",
          senderMail: "",
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("mailTemplates/list");
      const response = await this.$store.dispatch(
        "mailTemplates/mailTemplateAssignmentList"
      );

      if (response?.data?.data?.length) {
        await Promise.all(
          response.data.data.map(async (item) => {
            let template = this.mailTemplates.find(
              (t) => t.module === item.module
            );
            if (template) {
              Object.assign(template, item);
              if (template.mailTemplateId) {
                const matchedTemplate = this.templates.find(
                  (t) => t.id === template.mailTemplateId
                );
                if (matchedTemplate) {
                  template.mailTemplateId = matchedTemplate;
                }
              }
            }
          })
        );
      }
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    async createTemplate() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.$store.commit("showLoader", true);
            const modules = this.mailTemplates.map((template) => ({
              ...template,
              mailTemplateId: template.mailTemplateId?.id ?? "",
            }));

            await this.$store.dispatch(
              "mailTemplates/mailTemplateAssignmentSave",
              {
                modules: modules,
              }
            );
          } catch (e) {
            console.error("api error ___", e);
          } finally {
            this.$store.commit("showLoader", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
